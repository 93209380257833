<template>
    <div>
        <div v-if="loading" class="mt-5 text-center">
            <b-spinner type="grow" variant="dark"></b-spinner>
        </div>
        <UserAdd v-else @apply="apply" title="Apply for Access" submit="Apply"/>
    </div>
</template>

<script>
    import router from "../router";
    import UserAdd from "@/components/UserAdd";

    export default {
        name: "Apply",
        components: {UserAdd},
        data() {
            return {
                loading: false,
            };
        },
        methods: {
            async apply(form) {
                this.loading = true;
                const resp = await this.$store.dispatch('apply', form);
                if (resp) {
                    await this.$bvToast.toast(resp, {
                        title: 'Application Error',
                        variant: 'danger'
                    });
                } else {
                    await router.push({
                        name: "Applied"
                    });
                }
                this.loading = false;
            }
        }
    };
</script>

<style scoped>

</style>
