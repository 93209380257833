<template>
    <b-container class="mt-1">
        <b-form-group
            label="Search:"
        >
            <b-form-input
                v-model="search"
                placeholder="Search... (email, first name, last name, membership number)"
                required
            ></b-form-input>
        </b-form-group>
        <b-tabs class="mt-3" content-class="mt-3" fill>
            <b-tab title="Users" class="pl-4">
                <b-row class="w-100">
                    <b-button :to="{name:'UserAdd'}">Add User</b-button>
                </b-row>
                <b-row :key="user.id" v-for="user in allUsers" class="mt-2 w-100">
                    <UserCard :user="user" mode="user" @updated="load" @deleted="load"/>
                </b-row>
            </b-tab>
            <b-tab title="Applications" class="pl-4 pr-4">
                <b-row :key="user.id" v-for="user in applications" class="mt-1">
                    <UserCard :user="user" mode="application" @updated="load"  @deleted="load"/>
                </b-row>
            </b-tab>
            <b-tab title="Rejections" class="pl-4 pr-4">
                <b-row :key="user.id" v-for="user in rejectedUsers" class="mt-1">
                    <UserCard :user="user" mode="rejected" @updated="load" @deleted="load"/>
                </b-row>
            </b-tab>
        </b-tabs>
    </b-container>
</template>

<script>
    import * as fb from './../services/firebase';
    import UserCard from "../components/UserCard";

    export default {
        name: "Users",
        components: {UserCard},
        data() {
            return {
                loading: false,
                users: [],
                search: "",
            };
        },
        async created() {
            await this.load();
        },
        computed: {
            filteredUsers() {
                if (this.search === "") return this.users;
                return this.users.filter(val => val.email?.toLowerCase().includes(this.search.toLowerCase()) || val.firstName?.toLowerCase().includes(this.search.toLowerCase()) || val.lastName?.toLowerCase().includes(this.search.toLowerCase()) || val.membershipNumber?.toLowerCase().includes(this.search.toLowerCase()));
            },
            allUsers() {
                return this.filteredUsers.filter(val => !val.applied && !val.rejected);
            },
            rejectedUsers() {
                return this.filteredUsers.filter(val => val.rejected);
            },
            applications() {
                return this.filteredUsers.filter(val => val.applied);
            }
        },
        methods: {
            async load() {
                this.users = (await fb.users.orderBy("firstName").get()).docs.map(doc => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    };
                });
            }
        }
    };
</script>

<style scoped>

</style>
