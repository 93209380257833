<template>
    <b-container class="mt-5 text-center">
        <b-col cols="12">
            <h1>Your account has been Disabled</h1>
        </b-col>
        <b-col cols="12">
            <p>Please contact: <a href="mailto:sbu@sagf.co.za">sbu@sagf.co.za</a> for any questions</p>
        </b-col>
    </b-container>
</template>

<script>
    export default {
        name: "Applied"
    };
</script>

<style scoped>

</style>
