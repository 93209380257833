import * as fb from './../services/firebase';
import router from './../router/index';

export default {
    state: {
        userProfile: null,
        busy: false,
    },
    mutations: {
        setUserProfile(state, val) {
            state.userProfile = val;
        },
        setBusy(state, val) {
            state.busy = val;
        }
    },
    getters: {
        isLoggedIn: state => state.userProfile !== null,
        isAdmin: state => state.userProfile?.admin,
        userProfile: state => state.userProfile,
        busy: state => state.busy
    },
    actions: {
        async login({commit, dispatch, state}, form) {
            // sign user in
            commit('setBusy', true);
            try {
                const {user} = await fb.auth.signInWithEmailAndPassword(form.email, form.password);
                localStorage.setItem("user", user.uid);
                // fetch user profile and set in state
                await dispatch('fetchUserProfile', user);
                if (state.userProfile.applied) {
                    await dispatch('logout');
                    await router.push({name: 'Applied'});
                    return 'Application in Review';
                } else if (state.userProfile.rejected) {
                    await dispatch('logout');
                    await router.push({name: 'Rejected'});
                    return 'Application Rejected';
                } else if (state.userProfile.disabled) {
                    await dispatch('logout');
                    await router.push({name: 'Disabled'});
                    return 'Profile Disabled';
                }
                await commit('setBusy', false);

            } catch (ex) {
                commit('setBusy', false);
                return ex.message;
            }
        },
        async logout({commit}) {
            await fb.auth.signOut();
            localStorage.clear();
            commit('setUserProfile', null);
        },
        async apply({dispatch, commit}, form) {
            try {
                const {user} = await fb.auth.createUserWithEmailAndPassword(form.email, form.password);
                delete form.password;
                delete form.confirm;
                await fb.users.doc(user.uid).set({
                    ...form,
                    admin: false,
                    applied: true
                });
                await fb.functions.httpsCallable("sendApplicationNotification")({
                    applier: user.uid,
                });
                dispatch('logout');
            } catch (ex) {
                return ex.message;
            }
        },
        async createUser({dispatch, commit}, form) {
            try {
                commit('setBusy', true);
                await fb.functions.httpsCallable("createUser")(form);
                commit('setBusy', false);
            } catch (ex) {
                commit('setBusy', false);
                return ex.response.data;
            }
        },
        async fetchUserProfile({commit}, user) {
            // fetch user profile
            const userProfile = await fb.users.doc(user.uid).get();

            // set user profile in state
            commit('setUserProfile', userProfile.data());
            commit('setBusy', false);
        }
    }
};
