<template>
    <b-container>
        <b-col cols="8" offset="2">
            <b-card class="mt-5">
                <b-card-title>{{ title }}</b-card-title>
                <b-card-body>
                    <b-form @submit.prevent="apply">
                        <b-form-group
                            label="Email address:"
                        >
                            <b-form-input
                                v-model="form.email"
                                type="email"
                                placeholder="Enter email"
                                required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            label="First Name:"
                        >
                            <b-form-input
                                v-model="form.firstName"
                                placeholder="Enter First Name"
                                required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            label="Last Name:"
                        >
                            <b-form-input
                                v-model="form.lastName"
                                placeholder="Enter Last Name"
                                required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            label="Membership Number:"
                        >
                            <b-form-input
                                v-model="form.membershipNumber"
                                placeholder="Enter Gymnastics Membership Number"
                                required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            label="Password:"
                        >
                            <b-form-input
                                v-model="form.password"
                                type="password"
                                placeholder="Enter password"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            label="Confirm Password:"
                        >
                            <b-form-input
                                v-model="form.confirm"
                                type="password"
                                placeholder="Enter password"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <b-button style="float: right" class="mr-2" type="submit">{{ submit }}</b-button>
                    </b-form>
                </b-card-body>
            </b-card>
        </b-col>
    </b-container>
</template>

<script>
    export default {
        name: "UserAdd",
        props: {
            title: String,
            submit: String
        },
        data() {
            return {
                form: {
                    firstName: "",
                    lastName: "",
                    membershipNumber: "",
                    email: "",
                    password: "",
                    confirm: ""
                }
            };
        },
        methods: {
            async apply() {
                if (this.form.password === this.form.confirm) {
                    this.$emit('apply', this.form);
                } else {
                    this.$bvToast.toast("Passwords do not match", {
                        title: "Password Match",
                        variant: "danger"
                    });
                }
            }
        }
    };
</script>

<style scoped>

</style>
