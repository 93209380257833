<template>
    <b-container class="mt-5 text-center">
        <b-col cols="12">
            <h1>Application was Submitted</h1>
        </b-col>
        <b-col cols="12">
            <p>Thank you for your application, it has been sent for approval. This usually takes 48-72 hours to activate</p>
        </b-col>
    </b-container>
</template>

<script>
    export default {
        name: "Applied",
        props: {
            apply: null
        },
        async created() {
            await this.$bvToast.toast("Application Submitted", {
                title: 'Application',
                variant: 'success',
                autoHideDelay: 5000
            });
        }
    };
</script>

<style scoped>

</style>
