<template>
    <b-dropdown size="sm" variant="info" class="ml-1" no-caret dropleft>
        <template #button-content>
            <b-icon-three-dots-vertical/>
        </template>
        <b-dropdown-item class="d-flex" variant="danger" v-if="part.number !== 1" @click="remove">
            <b-icon-trash class="mr-1"/>
            <span>Remove</span>
        </b-dropdown-item>
        <b-dropdown-item variant="info" v-if="part.number !== 1" @click="swap(true)">
            <b-icon-arrow-up-circle class="mr-1"/>
            <span>Up</span>
        </b-dropdown-item>
        <b-dropdown-item variant="info" v-if="part.number !== this.parts.length" @click="swap(false)">
            <b-icon-arrow-down-circle class="mr-1"/>
            <span>Down</span>
        </b-dropdown-item>
        <b-dropdown-item variant="info" @click="clear">
            <b-icon-x-circle class="mr-1"/>
            <span>Clear</span>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
    import parts from "@/mixins/parts";
    import _ from 'lodash';

    export default {
        name: "EditPartMenu",
        props: {
            part: Object,
            parts: Array,
        },
        mixins: [parts],
        methods: {
            updateParts(newParts) {
                this.$emit('update:parts', newParts);
            },
            rebuild(newParts) {
                let count = 1;
                return _.sortBy(newParts, 'number').map(val => {
                    return {
                        ...val,
                        number: count++
                    };
                });
            },
            remove() {
                const newParts = this.parts.filter(val => val.number !== this.part.number);
                this.updateParts(this.rebuild(newParts));
            },
            clear() {
                this.$emit('update:part', this.newPart(this.part.number));
            },
            swap(up) {
                const otherNumber = up ? this.part.number - 1 : this.part.number + 1;
                const newParts = [
                    ...this.parts
                ];
                const other = newParts.findIndex(val => val.number === otherNumber);
                const part = newParts.findIndex(val => val.number === this.part.number);
                newParts[other] = {
                    ...this.parts[other],
                    number: this.part.number
                };
                newParts[part] = {
                    ...this.parts[part],
                    number: otherNumber
                };
                this.updateParts(_.sortBy(newParts, 'number'));
            }
        }
    }
    ;
</script>

<style scoped>

</style>
