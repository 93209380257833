<template>
    <b-container>
        <b-col v-if="message" cols="12" class="mt-2">
            <b-alert variant="warning" dismissible :show="true">{{ message }}</b-alert>
        </b-col>
        <b-col cols="8" offset="2">
            <b-card class="mt-5">
                <b-card-title>Login</b-card-title>
                <b-card-body>
                    <b-form @submit.prevent="login">
                        <b-form-group
                            label="Email address:"
                        >
                            <b-form-input
                                v-model="form.email"
                                type="email"
                                placeholder="Enter email"
                                required
                                :disabled="loading"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            label="Password:"
                        >
                            <b-form-input
                                v-model="form.password"
                                type="password"
                                placeholder="Enter password"
                                required
                                :disabled="loading"
                            ></b-form-input>
                        </b-form-group>
                        <b-button style="float: right" type="submit">Login</b-button>
                        <b-button style="float: right" class="mr-2" :to="{name: 'Apply'}">Apply</b-button>

                        <router-link to="/forgot">Forgot your Password?</router-link>
                    </b-form>
                </b-card-body>
            </b-card>
        </b-col>
    </b-container>
</template>

<script>
    import router from "../router";

    export default {
        name: "Login",
        props: {
            message: String,
            cssFile: String
        },
        data() {
            return {
                loading: false,
                form: {
                    email: "",
                    password: ""
                }
            };
        },
        methods: {
            async login() {
                this.loading = true;
                const resp = await this.$store.dispatch('login', this.form);
                if (resp) {
                    this.loading = false;
                    this.$bvToast.toast(resp, {
                        title: 'Login Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    });
                } else {
                    await router.push({name: "Home"});
                }
            }
        }
    };
</script>

<style scoped>

</style>
