<template>
    <b-button v-bind="$attrs" @click="onConfirm">
        <span v-if="confirm">Confirm?</span>
        <slot v-else></slot>
    </b-button>
</template>

<script>
    export default {
        name: "ConfirmButton",
        data()  {
            return{
                confirm: false
            }
        },
        methods: {
            onConfirm(event) {
                if (this.confirm) {
                    this.confirm = false;
                    return this.$emit('click', event);
                }
                this.confirm = true
            }
        }
    };
</script>

<style scoped>

</style>
