<template>
    <div>
        <NavBar/>
        <Transition name="fade" mode="out-in">
            <router-view key="router" v-if="!busy"/>
        </Transition>
    </div>
</template>

<style lang="scss">

</style>
<script>
    import NavBar from "./components/NavBar";
    import {mapGetters} from "vuex";

    export default {
        components: {NavBar},
        computed: {
            ...mapGetters(['busy'])
        },
        created() {
            if (localStorage.getItem("user")) {
                const user = {
                    uid: localStorage.getItem("user")
                };
                this.$store.dispatch('fetchUserProfile', user);
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
