import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyA_iFM_Kua4RpIbkmLNZIIeCiRowg2efuc",
    authDomain: "bs-sagf-resource.firebaseapp.com",
    projectId: "bs-sagf-resource",
    storageBucket: "bs-sagf-resource.appspot.com",
    messagingSenderId: "734326546374",
    appId: "1:734326546374:web:cb643a79a146482c241cc7",
    measurementId: "G-KRFYYG9KSX",
};

firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
const functions = firebase.app().functions('europe-west1');
const auth = firebase.auth();
const storage = firebase.storage().ref();

const users = db.collection('users');
const resources = db.collection(`${process.env.VUE_APP_DEVELOP === "true" ? "develop-" : ""}resources`);
const meta = db.collection('meta');
const bucket = (name) => storage.child(`${process.env.VUE_APP_DEVELOP === "true" ? "develop-" : ""}${name}`);

export {
    db,
    auth,
    users,
    resources,
    storage,
    meta,
    bucket,
    functions
};
