import * as fb from "./../services/firebase";
import {storage, bucket} from "@/services/firebase";
import Vue from 'vue';

export default {
    state: {
        resources: [],
        resource: null,
        progress: {},
        progressTotal: 0
    },
    getters: {
        resources: state => state.resources,
        resource: state => state.resource,
        progressRun: state => Object.keys(state.progress).reduce((a, b) => a + state.progress[b], 0),
        progressTotal: state => state.progressTotal
    },
    actions: {
        async createResource({commit}, resource) {
            const folder = bucket(resource.name);
            //Commence upload of files
            commit('clearProgress');
            resource.parts = await Promise.all(resource.parts.map(async part => {
                    if (part.file instanceof File) {
                        const putRef = folder.child(part.file.name).put(part.file);
                        commit('addProgressTotal', part.file.size);
                        putRef.on("state_changed", (snapshot => {
                            commit('setProgress', {
                                file: part.file.name,
                                progress: snapshot.bytesTransferred
                            });
                        }));
                        const file = await putRef;
                        const url = await file.ref.getDownloadURL();
                        return {
                            ...part,
                            file: url
                        };
                    } else return part;
                }
            ));
            await fb.resources.add(resource);
        },
        async editResource({commit}, resource) {
            const folder = bucket(resource.name);
            //Add all missing files
            commit('clearProgress');
            resource.parts = await Promise.all(resource.parts.map(async part => {
                    if (part.file instanceof File) {
                        const putRef = folder.child(part.file.name).put(part.file);
                        commit('addProgressTotal', part.file.size);
                        putRef.on("state_changed", (snapshot => {
                            commit('setProgress', {
                                file: part.file.name,
                                progress: snapshot.bytesTransferred
                            });
                        }));
                        const file = await putRef;
                        const url = await file.ref.getDownloadURL();
                        return {
                            ...part,
                            file: url
                        };
                    } else return part;
                }
            ));
            const id = resource.id;
            delete resource.id;
            await fb.resources.doc(id).set(resource);
        },
        async getNonProtectedResources({commit, getters}) {
            const resources = await fb.resources.where('protected', '==', false).get();
            commit('setResources', resources.docs.filter(val => !val.data().hidden || getters.isAdmin).map(resource => {
                    return {
                        ...resource.data(),
                        id: resource.id
                    };
                }
            ));
        },
        async getResources({commit, getters}, {category}) {
            const resources = await fb.resources.where('category', '==', category).where('protected', '==', true).get();
            commit('setResources', _.groupBy(resources.docs.filter(val => !val.data().hidden || getters.isAdmin).map(resource => {
                    return {
                        ...resource.data(),
                        id: resource.id
                    };
                }
            ), 'subCategory'));
        },
        async getResource({commit}, id) {
            const resource = await fb.resources.doc(id).get();
            commit('setResource', resource.data());
        },
        async deleteResource({commit}, id) {
            await fb.resources.doc(id).delete();
        }
    },
    mutations: {
        setResources(state, resources) {
            state.resources = resources;
        },
        setResource(state, resource) {
            state.resource = resource;
        },
        clearProgress(state) {
            state.progressTotal = 0;
            state.progress = {};
        },
        addProgressTotal(state, total) {
            state.progressTotal += total;
        },
        setProgress(state, {file, progress}) {
            Vue.set(state.progress, file, progress);
        }
    }
};
