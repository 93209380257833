export default {
    methods: {
        newPart(number) {
            return {
                number: number ?? this.parts.length + 1,
                type: 'video'
            };
        }
    }
};
