<template>
    <div v-if="loading" class="text-center mt-5">
        <b-spinner variant="dark" type="grow"></b-spinner>
    </div>
    <div v-else>
        <div class="text-center mt-2">
            <h2>{{ resource.name }}</h2>
        </div>
        <b-container class="m-0 ml-1 cont">
            <b-row class="h-100">
                <b-col class="flex-column" cols="8">
                    <b-col class="p-0 viewport content h-100">
                        <Transition mode="out-in" name="content">
                            <template v-if="selectedPart">
                                <div key="part" v-if="selectedPart.type === 'video'"
                                     class="p-1">
                                    <Transition name="fade">
                                        <b-overlay spinner-type="grow" :show="loadingContent" rounded="sm" opacity="1">
                                            <video-player class="video-player-box"
                                                          ref="videoPlayer"
                                                          :options="playerOptions"
                                                          :playsinline="true">
                                            </video-player>
                                        </b-overlay>
                                    </Transition>
                                </div>
                                <div key="part" v-if="selectedPart.type === 'pdf'"
                                     class="h-100 d-flex justify-content-center align-items-center">
                                    <iframe width="100%" height="100%" style="border: none"
                                            :src="selectedPart.file"></iframe>
                                    <!--                                    <b-button :href="selectedPart.file" target="_blank" class="mt-5 mb-5">View Document
                                                                        </b-button>-->
                                </div>
                                <div key="part" v-if="selectedPart.type === 'picture'">
                                    <Transition name="fade">
                                        <b-overlay spinner-type="grow" :show="loadingContent" rounded="sm" opacity="1">
                                            <b-img style="max-height: 85vh"
                                                   fluid :src="selectedPart.file" alt="image"/>
                                        </b-overlay>
                                    </Transition>
                                </div>
                                <div key="part" v-if="selectedPart.type === 'music'"
                                     class="d-flex justify-content-center align-items-center h-100">
                                    <Transition name="fade">
                                        <b-overlay
                                            class="d-flex justify-content-center align-items-center h-100 flex-column"
                                            spinner-type="grow" :show="loadingContent" rounded="sm" opacity="1">
                                            <audio controls preload="auto" ref="audioPlayer">
                                                <source :src="selectedPart.file">
                                            </audio>
                                            <span class="text-muted">To download click the three dots for options.</span>
                                        </b-overlay>
                                    </Transition>
                                </div>
                                <div key="part" v-if="selectedPart.type === 'quiz'">
                                    <b-container class="ml-5">
                                        <b-row><h4>{{ selectedPart.question }}</h4></b-row>
                                        <b-form @submit.prevent="answer(selectedPart)" v-if="!selectedPart.answered">
                                            <b-row v-for="answer in selectedPart.answers">
                                                <b-form-radio v-model="currentAnswer" name='answers'
                                                              :value="answer.number">
                                                    {{ answer.answer }}
                                                </b-form-radio>
                                            </b-row>
                                            <b-button type="submit" variant="primary">Submit
                                            </b-button>
                                        </b-form>
                                        <div v-else>
                                            <b-row v-for="answer in selectedPart.answers">
                                                <b-form-radio v-model="currentAnswer" name='answers'
                                                              :value="answer.number"
                                                              disabled
                                                >
                                                    {{ answer.answer }}
                                                </b-form-radio>
                                                <span v-if="answer.number === selectedPart.answer" class="correct ml-2"><b-icon-check/></span>
                                                <span
                                                    v-if="answer.number === currentAnswer && answer.number !== selectedPart.answer"
                                                    class="wrong ml-2"><b-icon-x/></span>
                                            </b-row>
                                        </div>
                                    </b-container>
                                </div>
                                <div key="part" v-if="selectedPart.type === 'choice'">
                                    <b-container class="ml-5">
                                        <b-row><h4>{{ selectedPart.question }}</h4></b-row>
                                        <b-form @submit.prevent="answer(selectedPart)" v-if="!selectedPart.answered">
                                            <b-row>
                                                <b-form-checkbox-group stacked>
                                                    <b-form-checkbox v-model="currentAnswer" name='answers'
                                                                     :value="answer.number"
                                                                     v-for="answer in selectedPart.answers">
                                                        {{ answer.answer }}
                                                    </b-form-checkbox>
                                                </b-form-checkbox-group>
                                            </b-row>

                                            <b-button type="submit" variant="primary">Submit
                                            </b-button>
                                        </b-form>
                                        <div v-else>
                                            <b-row v-for="answer in selectedPart.answers">
                                                <b-form-checkbox disabled
                                                                 :checked="currentAnswer.includes(answer.number)">
                                                    {{ answer.answer }}
                                                </b-form-checkbox>
                                                <span v-if="answer.isAnswer" class="correct ml-2"><b-icon-check/></span>
                                                <span v-if="currentAnswer.includes(answer.number) && !answer.isAnswer"
                                                      class="wrong ml-2"><b-icon-x/></span>
                                            </b-row>
                                        </div>
                                    </b-container>
                                </div>
                            </template>
                            <div v-else key="loading" class="h-100 d-flex justify-content-center align-items-center">
                                <b-spinner type="grow"></b-spinner>
                            </div>
                        </Transition>
                    </b-col>
                    <b-row/>
                </b-col>
                <b-col cols="4">
                    <div style="max-height:80vh;overflow: auto" class="part-holder">
                        <b-card
                            :class="{'p-0': true, 'm-0': true, part: true, 'mb-1': true, selected: selectedPart.number === part.number}"
                            v-for="part in resource.parts" @click="() =>{
                                    selectPart(part);
                                    currentAnswer = ''
                                    part.answered = false
                                }"
                            :key="part.number">
                            <b-card-body class="p-0 m-0">{{
                                    part.name ? part.name : `Part ${part.number}`
                                }}
                            </b-card-body>
                        </b-card>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mt-2"><b-button @click="back" class="mb-2">Back</b-button></b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import pdf from 'vue-pdf';
    import Vue from 'vue';
    import axios from "axios";

    export default {
        name: "ResourceView",
        components: {
            pdf
        },
        props: {
            id: {
                required: true,
                type: String
            }
        },
        computed: {
            ...mapGetters(['resource', 'isLoggedIn']),
            playerOptions() {
                return {
                    muted: false,
                    language: 'en',
                    playbackRates: [0.7, 1.0, 1.5, 2.0],
                    aspectRatio: "16:9",
                    sources: [{
                        type: "video/mp4",
                        src: this.selectedPart.file
                    }],
                };
            }
        },
        data() {
            return {
                currentPage: -1,
                loading: true,
                loadingContent: true,
                selectedPart: null,
                currentAnswer: 1
            };
        },
        async created() {
            await this.$store.dispatch('getResource', this.id);
            if (this.resource.protected && !this.isLoggedIn) {
                await this.$router.push({name: "Login", query: {message: "Please login to View"}});
                return;
            }
            this.loading = false;
            await this.selectPart(this.resource.parts[0]);
        },
        methods: {
            answer(part) {
                Vue.set(part, 'answered', true);
            },
            async back() {
                await this.$router.push({
                    name: "Home", query: {
                        current: this.resource.category
                    }
                });
            },
            async selectPart(part) {
                this.selectedPart = false;
                let timeout = 500
                if (part.type === 'picture') {
                    await this.preloadPicture(part.file);
                }
                setTimeout(() => {
                    this.selectedPart = part;
                    this.loadingContent = false;
                }, timeout);
            },
            preloadPicture(src) {
                const image = new Image();
                let loaded = false;
                image.onload = function () {
                    console.log('Loaded');
                    loaded = true;
                };
                image.src = src;
                return new Promise(resolve => {
                    const interval = setInterval(() => {
                        if (loaded) {
                            clearInterval(interval);
                            resolve();
                        } else {
                            console.log('Loading');
                        }
                    }, 50);
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    .content-enter-active {
        transition: opacity .2s;
    }

    .content-enter, .content-leave-to /* .fade-leave-active below version 2.1.8 */
    {
        opacity: 0;
    }

    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }

    ::-webkit-scrollbar-thumb {
        background: #e1e1e1;
        border: 0px none #ffffff;
        border-radius: 50px;
    }


    ::-webkit-scrollbar-track {
        background: #666666;
        border: 0px none #ffffff;
        border-radius: 50px;
    }

    ::-webkit-scrollbar-track:hover {
        background: #666666;
    }

    ::-webkit-scrollbar-corner {
        background: transparent;
    }

    .content {
        border: 1px solid var(--gray);
        border-radius: 5px;
        padding: 0.5rem;
    }

    .part-holder {
        border: 1px solid var(--gray);
        border-radius: 5px;
        padding: 0.5rem;
        min-height: 100%;
    }

    .part {
        user-select: none;
        border: 2px solid #FF0E49;

        &:hover {
            cursor: pointer;
            background-color: rgba(#FF0E49, 0.02);
        }

        &.selected {
            background-color: rgba(#FF0E49, 0.05);
        }
    }

    .cont {
        min-height: 75vh;
        height: 75vh;
        max-width: 99%;
    }


    .correct {
        color: var(--green) !important;
    }

    .wrong {
        color: var(--red) !important
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }


</style>

