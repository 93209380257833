import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store/index';
import Resource from "../views/ResourceView";
import ResourceEdit from "../views/ResourceEdit";
import Login from "../views/Login";
import Apply from "../views/Apply";
import Applied from "../views/Applied";
import Rejected from "../views/Rejected";
import Users from "../views/Users";
import Disabled from "../views/Disabled";
import NewUser from "@/views/NewUser";
import Forgot from "@/views/Forgot";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        props: route => ({...route.query})
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        props: route => ({...route.query})
    },
    {
        path: '/forgot',
        name: 'Forgot',
        component: Forgot,
        props: route => ({...route.query})
    },
    {
        path: '/applied',
        name: 'Applied',
        component: Applied,
        props: route => ({...route.query})
    },
    {
        path: '/rejected',
        name: 'Rejected',
        component: Rejected
    },
    {
        path: '/disabled',
        name: 'Disabled',
        component: Disabled
    },
    {
        path: '/logout',
        name: 'Logout',
        beforeEnter: async (to, from, next) => {
            await store.dispatch("logout");
            next('/login');
        }
    },
    {
        path: '/apply',
        name: 'Apply',
        component: Apply
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
        meta: {
            requiresAdmin: true
        }
    },
    {
        name: 'UserAdd', path: '/users/add', component: NewUser,
        meta: {
            requiresAdmin: true
        }
    },
    {
        path: "/resource/add",
        name: "ResourceAdd",
        component: ResourceEdit,
        meta: {
            requiresAdmin: true
        }
    },
    {
        path: "/resource/:id/edit",
        name: "ResourceEdit",
        component: ResourceEdit,
        props: true,
        meta: {
            requiresAdmin: true
        }
    },
    {
        path: "/resource/:id",
        name: "ResourceView",
        component: Resource,
        props: true
    },
    {
        path: "*",
        beforeEnter: ((to, from, next) => {
            next('/');
        })
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
    const requiresAdmin = to.matched.some(x => x.meta.requiresAdmin);
    store.commit('setBusy', true);
    if (localStorage.getItem('user') && !store.getters.userProfile) {
        await store.dispatch('fetchUserProfile', {
            uid: localStorage.getItem('user')
        });
    }
    if (requiresAdmin && !store.getters.userProfile.admin) {
        store.commit('setBusy', false);
        next('/login');
    } else if (requiresAuth && !store.getters.userProfile) {
        store.commit('setBusy', false);
        next('/login');
    } else {
        store.commit('setBusy', false);
        next();
    }
});

export default router;
