<template>
    <b-container>
        <b-col v-if="message" cols="12" class="mt-2">
            <b-alert variant="warning" dismissible :show="true">{{ message }}</b-alert>
        </b-col>
        <b-col cols="8" offset="2">
            <b-card class="mt-5">
                <b-card-title>Forgot your Password</b-card-title>
                <b-card-body>
                    <b-form @submit.prevent="reset">
                        <b-form-group
                            label="Email address:"
                        >
                            <b-form-input
                                v-model="form.email"
                                type="email"
                                placeholder="Enter email"
                                required
                                :disabled="loading"
                            ></b-form-input>
                        </b-form-group>

                        <b-button style="float: right" type="submit">Reset</b-button>
                    </b-form>
                </b-card-body>
            </b-card>
        </b-col>
    </b-container>
</template>

<script>
    import {auth} from "@/services/firebase";

    export default {
        name: "Forgot",
        data() {
            return {
                loading: false,
                message: null,
                form: {
                    email: ""
                }
            };
        },
        methods: {
            async reset() {
                try {
                    this.loading = true;
                    await auth.sendPasswordResetEmail(this.form.email);
                }
                catch(ex) {
                    console.log(ex);
                }
                finally {
                    await this.$router.push(`/login?message=If a user with this email exist we have sent them a reset request. Please check your email!`, )
                }
            }
        }
    };
</script>

<style scoped>

</style>
