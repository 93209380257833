<template>
    <b-card>
        <template #header>
            {{ user.email }}
            <b-button :disabled="setting" v-if="userMode" style="float: right" @click="toggleAdmin"
                      :variant="user.admin ? 'success' : ''">
                {{
                    user.admin ? "Disable Admin" :
                        "Enable Admin"
                }}
            </b-button>
            <b-button :disabled="setting" v-if="userMode" style="float: right" @click="changePassword" class="mr-1"
                      variant="warning">
                Change Password
            </b-button>
            <b-button :disabled="setting" v-if="userMode" style="float: right" @click="toggleDisable" class="mr-1"
                      :variant="user.disabled ? 'success' : 'warning'">{{
                    user.disabled ? "Enable" :
                        "Disable"
                }}
            </b-button>
            <ConfirmButton :disabled="setting" v-if="userMode" style="float: right" @click="deleteUser" class="mr-1"
                           variant="danger">Delete
            </ConfirmButton>
            <div class="d-flex mr-3 float-right h-100 justify-content-center align-items-center">
                <b-spinner type="grow" small v-if="setting"></b-spinner>
            </div>
            <b-button :disabled="setting" variant="success" v-if="applicationMode" style="float: right"
                      @click="acceptApplication()"
                      class="ml-1">Accept
            </b-button>
            <b-button :disabled="setting" variant="danger" v-if="applicationMode" style="float: right"
                      @click="rejectApplication()">Reject
            </b-button>
            <b-button :disabled="setting" variant="success" v-if="rejectedMode" style="float: right"
                      @click="acceptApplication()"
                      class="ml-1">Accept
            </b-button>
        </template>
        <b-container>
            <b-row>
                <b-table :items="tableItems" :fields="tableFields" small>

                </b-table>
            </b-row>
        </b-container>
    </b-card>
</template>

<script>
    import * as fb from './../services/firebase';
    import ConfirmButton from "@/components/ConfirmButton";

    export default {
        name: "UserCard",
        components: {ConfirmButton},
        props: {
            mode: String,
            user: Object
        },
        data() {
            return {
                setting: false,
                deleting: false,
                tableFields: [
                    'firstName',
                    'lastName',
                    'membershipNumber',
                ],
                password: 'Penis'
            };
        },
        computed: {
            tableItems() {
                return [
                    {
                        ...this.user
                    }
                ];
            },
            userMode() {
                return this.mode === "user";
            },
            applicationMode() {
                return this.mode === 'application';
            },
            rejectedMode() {
                return this.mode === 'rejected';
            }
        },
        methods: {
            async changePassword() {
                const h = this.$createElement;
                let password = "";
                const node = h('b-form-input', {
                    attrs: {
                        "placeholder": "Enter password",
                    },
                    on: {
                        change(value) {
                            password = value;
                        }
                    },
                });
                if (await this.$bvModal.msgBoxConfirm([node], {
                    title: "Enter new Password",
                    okTitle: "Change",
                    okVariant: "secondary",
                    cancelVariant: "warning"
                })) {
                    if (password !== "") {
                        this.setting = true;
                        await fb.functions.httpsCallable('updatePassword')({
                            email: this.user.email,
                            password: password
                        });
                        this.setting = false;
                        return this.$bvToast.toast("Changed Password", {
                            variant: 'success',
                        });
                    }
                }
            },
            async setField(field, value) {
                await fb.users.doc(this.user.id).update(field, value);
                this.$emit('updated');
            },
            async deleteUser() {
                this.setting = true;
                await fb.functions.httpsCallable("deleteUser")({
                    email: this.user.email
                });
                this.$emit('deleted');
                this.setting = false;
            },
            async toggleDisable() {
                this.setting = true;
                if (this.user.disabled) {
                    await this.setField('disabled', false);
                    this.$bvToast.toast('Enabled User', {
                        variant: 'success'
                    });
                } else {
                    await this.setField('disabled', true);
                    this.$bvToast.toast('Disabled User', {
                        variant: 'success'
                    });
                }
                this.setting = false;
            },
            async toggleAdmin() {
                this.setting = true;
                if (this.user.admin) {
                    await this.setField('admin', false);
                    this.$bvToast.toast('Disabled User Admin', {
                        variant: 'success'
                    });
                } else {
                    await this.setField('admin', true);
                    this.$bvToast.toast('Enabled User Admin', {
                        variant: 'success'
                    });
                }
                this.setting = false;
            },
            async acceptApplication() {
                await fb.users.doc(this.user.id).update('disabled', false, 'rejected', false, "applied", false);
                this.$emit('updated');
                await fb.functions.httpsCallable("sendApplicationResult")({
                    receiver: this.user.id,
                    result : "success"
                });
                this.$bvToast.toast('Accepted User', {
                    variant: 'success'
                });
            },
            async rejectApplication() {
                await fb.users.doc(this.user.id).update('disabled', false, 'rejected', true, "applied", false);
                this.$emit('updated');
                await fb.functions.httpsCallable("sendApplicationResult")({
                    receiver: this.user.id,
                    result : "denied"
                });
                this.$bvToast.toast('Rejected User', {
                    variant: 'warning'
                });
            },
        }
    };
</script>

<style scoped>
    .card {
        width: 100%;
    }
</style>
