import {meta} from './firebase';
import * as _ from 'lodash';

export const getCategories = async () => {
    const doc = (await meta.doc("categories").get()).data();
    return [
        {
            key: "general",
            name: "General"
        },
        ..._.sortBy(Object.keys(doc).map(key => {
            return {
                key,
                name: doc[key]
            };
        }), "name")
    ];
};

export const getSubCategories = async () => {
    return [
        {
            key: "competition",
            name: "Competition"
        },
        {
            key: "coaching",
            name: "Coaching"
        },
        {
            key: "judging",
            name: "Judging"
        },
        {
            key: "general",
            name: "General"
        },
    ];
};

