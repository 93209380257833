<template>
    <div v-if="loading" class="mt-5 text-center">
        <b-spinner type="grow" variant="dark"></b-spinner>
    </div>
    <b-container class="mt-5" style="text-align: center" v-else>
        <div class="d-flex justify-content-around">
            <b-button @click="back" class="mb-2">Back</b-button>
            <b-button @click="remove" class="mb-2" variant="warning">Remove Resource</b-button>
            <b-button @click="$bvModal.show('bv-complete-modal')" class="mb-2">Complete</b-button>
        </div>
        <b-card no-body class="mb-2 mt-2" v-for="(part, idx) in parts" :key="part.number">
            <b-card-header header-tag="header" class="p-1 d-flex" role="tab">
                <b-button class="flex-fill" v-b-toggle="[`part-${part.number}`]" variant="info" size="lg">
                    {{ part.name && part.name !== "" ? part.name : `Part ${part.number}` }}
                </b-button>
                <EditPartMenu :part.sync="parts[idx]" :parts.sync="parts"/>
            </b-card-header>
            <b-collapse :id="`part-${part.number}`" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <b-form-input v-model="part.name" placeholder="Enter part name"></b-form-input>
                    <b-tabs pills class="mt-2" vertical>
                        <b-tab title="Video" @click="part.type= 'video'" :active="part.type === 'video'">
                            <b-form-file
                                v-model="part.file"
                                :state="Boolean(part.file)"
                                browse-text="Choose"
                                placeholder="Choose a Video or drop it here..."
                                drop-placeholder="Drop Video here..."
                                accept=".mp4"
                            ></b-form-file>
                        </b-tab>
                        <b-tab title="PDF" @click="part.type= 'pdf'" :active="part.type === 'pdf'">
                            <b-form-file
                                v-model="part.file"
                                :state="Boolean(part.file)"
                                browse-text="Choose"
                                placeholder="Choose a PDF or drop it here..."
                                drop-placeholder="Drop PDF here..."
                                accept=".pdf"
                            ></b-form-file>
                        </b-tab>
                        <b-tab title="Picture" @click="part.type= 'picture'" :active="part.type === 'picture'">
                            <b-form-file
                                v-model="part.file"
                                :state="Boolean(part.file)"
                                browse-text="Choose"
                                placeholder="Choose a Picture or drop it here..."
                                drop-placeholder="Drop Picture here..."
                                accept=".jpeg,.jpg,.png"
                            ></b-form-file>
                        </b-tab>
                        <b-tab title="Music" @click="part.type= 'music'" :active="part.type === 'music'">
                            <b-form-file
                                v-model="part.file"
                                :state="Boolean(part.file)"
                                browse-text="Choose"
                                placeholder="Choose a Music File or drop it here..."
                                drop-placeholder="Drop Music File here..."
                                accept=".mp3,.ogg,.wav"
                            ></b-form-file>
                        </b-tab>
                        <b-tab title="Quiz" @click="part.type= 'quiz'" :active="part.type === 'quiz'">
                            <b-form-textarea class="m-2"
                                             id="textarea"
                                             v-model="part.question"
                                             placeholder="Enter a question..."
                                             rows="3"
                                             :disabled="uploading"
                            ></b-form-textarea>
                            <b-button class="mt-2" @click="addAnswer(idx)">Add Answer</b-button>
                            <b-row v-for="answer in part.answers" class="m-2">
                                <b-col cols="1" class="p-0 d-flex align-items-center justify-content-center">
                                    <b-form-radio v-model="part.answer"
                                                  name="some-radios" :value="answer.number"></b-form-radio>
                                </b-col>
                                <b-col cols="11">
                                    <b-form-input class="mt-1" v-model="answer.answer"
                                                  placeholder="Enter an answer"></b-form-input>
                                </b-col>
                            </b-row>

                        </b-tab>
                        <b-tab title="Multiple Choice" @click="part.type= 'choice'" :active="part.type === 'choice'">
                            <b-form-textarea class="m-2"
                                             id="textarea"
                                             v-model="part.question"
                                             placeholder="Enter a question..."
                                             rows="3"
                                             :disabled="uploading"
                            ></b-form-textarea>
                            <b-button class="mt-2" @click="addAnswer(idx)">Add Answer</b-button>
                            <b-row v-for="(answer, idx) in part.answers" class="m-2">
                                <div class="form-check d-flex align-items-center justify-content-center"
                                     style="width: 100%">
                                    <input class="mt-1 custom-control custom-control-inline custom-checkbox"
                                           type="checkbox"
                                           v-model="part.answers[idx].isAnswer"
                                           :value="answer.number"
                                    />
                                    <b-form-input v-model="answer.answer"
                                                  style="width: 100%"
                                                  placeholder="Enter an answer"></b-form-input>
                                </div>
                            </b-row>
                        </b-tab>
                    </b-tabs>
                    <b-form-textarea class="mt-2"
                                     id="textarea"
                                     v-if="!['quiz', 'choice'].includes(part.type)"
                                     v-model="part.description"
                                     placeholder="Enter description..."
                                     rows="3"
                                     :disabled="uploading"
                    ></b-form-textarea>
                </b-card-body>
            </b-collapse>
        </b-card>
        <b-button class="mb-2" @click="addPart">Add</b-button>
        <b-modal id="bv-complete-modal" hide-footer>
            <template #modal-title>
                <h6>Details</h6>
            </template>
            <div class="d-block text-center">
                <b-form>
                    <b-form-input v-model="name" placeholder="Enter resource name" :disabled="uploading"></b-form-input>
                    <b-form-textarea class="mt-2"
                                     id="textarea"
                                     v-model="description"
                                     placeholder="Enter description..."
                                     rows="3"
                                     :disabled="uploading"
                    ></b-form-textarea>
                    <b-form-select v-model="category" class="mt-1" :options="categories"
                                   value-field="key"
                                   text-field="name">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                -- Please select an Category --
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                    <b-form-select v-model="subCategory" class="mt-1" :options="subCategories"
                                   value-field="key"
                                   text-field="name">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                -- Please select an Sub-Category --
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="text-left">
                        <b-form-checkbox
                            class="mt-1"
                            v-model="protected"
                            switch
                        >
                            Protected Resource
                        </b-form-checkbox>
                        <b-form-checkbox
                            class="mt-1"
                            v-model="hidden"
                            switch
                        >
                            Hidden Resource
                        </b-form-checkbox>
                        <b-form-select v-model="image" class="mt-1" :options="images">
                            <template #first>
                                <b-form-select-option :value="null" disabled>
                                    -- Please select an Image --
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="text-center">
                            <b-img class=" mt-2 w-15" v-if="image" :src="`/${image}`">
                            </b-img>
                        </div>
                    </div>
                    <b-button class="mt-2 mr-5" :disabled="uploading" @click="$bvModal.hide('bv-complete-modal')">Cancel
                    </b-button>
                    <b-button class="mt-2 ml-5" :disabled="uploading" @click="create" v-if="id === -1">Create</b-button>
                    <b-button class="mt-2 ml-5" :disabled="uploading" @click="edit" v-else>Edit</b-button>

                </b-form>
                <div class="text-center mt-1">
                    <h6 v-show="uploading">Uploading (Do not close)</h6>
                    <b-progress-bar v-show="uploading" show-progress animated :max="progressTotal"
                                    :value="progressRun"/>
                </div>
            </div>
        </b-modal>
    </b-container>
</template>

<script>
    import {mapGetters} from "vuex";
    import Vue from 'vue';
    import EditPartMenu from "@/components/EditPartMenu";
    import parts from "@/mixins/parts";
    import {getCategories, getSubCategories} from "@/services/meta";

    const initialData = () => {
        return {
            name: "",
            description: "",
            loading: true,
            category: null,
            subCategory: null,
            uploading: false,
            protected: false,
            hidden: false,
            gymSALogo: false,
            image: null,
            parts: [
                {
                    number: 1,
                    type: 'video'
                }
            ],
            categories: [],
            subCategories: [],
            images: [
                {value: 'SAG-logo-alpha-small-logo.png', text: 'Gymnastics SA Logo'},
                {value: 'Young Leaders Series.png', text: 'Young Leaders Series'},
                {value: 'Gym Connect logo_black.jpg', text: 'Gym Connect (Black)'},
                {value: 'Gym Connect logo_white.jpg', text: 'Gym Connect (White)'},
                {value: 'SAGF - ACRO.png', text: 'Acrobatics Logo'},
                {value: 'SAGF - AER.png', text: 'Aerobics Logo'},
                {value: 'SAGF - GFA.png', text: 'Gym For All Logo'},
                {value: 'SAGF - MAG.png', text: 'Men`s Artistic Logo'},
                {value: 'SAGF - PARK.png', text: 'Parkour Logo'},
                {value: 'SAGF - RG.png', text: 'Rhythmic Logo'},
                {value: 'SAGF - RS.png', text: 'Rope Skipping Logo'},
                {value: 'SAGF - TRA.png', text: 'Trampoline Logo'},
                {value: 'SAGF - WAG.png', text: 'Women\'s Artistic Logo'},
            ]
        };
    };

    export default {
        name: "ResourceEdit",
        components: {EditPartMenu},
        mixins: [parts],
        props: {
            id: {
                required: false,
                default: -1
            }
        },
        data() {
            return initialData();
        },
        watch: {
            async $route(to, from, next) {
                if (to !== from) {
                    Object.assign(this.$data, initialData());
                    await this.onCreate();
                }
            }
        },
        async created() {
            await this.onCreate();
        },
        computed: {
            ...mapGetters(['resource', 'progressRun', 'progressTotal']),
        },
        methods: {
            async onCreate() {
                this.categories = await getCategories();
                this.subCategories = await getSubCategories();
                if (this.id !== -1) {
                    await this.$store.dispatch('getResource', this.id);
                    this.parts = this.resource.parts;
                    this.name = this.resource.name;
                    this.description = this.resource.description;
                    this.protected = this.resource.protected ?? false;
                    this.hidden = this.resource.hidden ?? false;
                    this.category = this.resource.category ?? null;
                    this.subCategory = this.resource.subCategory ?? null;
                    this.image = this.resource.image ?? null;
                    this.loading = false;
                } else this.loading = false;
            },
            addAnswer(idx) {
                if (!this.answer) {
                    if (!this.parts[idx].type === 'choice') {
                        this.answer = [];
                    }
                }
                if (!this.parts[idx].answers) {
                    Vue.set(this.parts, idx, {
                        ...this.parts[idx],
                        answers: [
                            {
                                number: 1,
                                answer: ""
                            }
                        ]
                    });
                } else {
                    this.parts[idx].answers.push({
                        number: this.parts[idx].answers.length + 1,
                        answer: ""
                    });
                }
            },
            addPart() {
                this.parts.push(this.newPart());
            },
            async remove() {
                if (await this.$bvModal.msgBoxConfirm("Are you sure you want to remove this resource?", {
                    okTitle: 'Remove',
                    okVariant: "warning",
                    cancelVariant: "secondary"
                })) {
                    this.loading = true;
                    await this.$store.dispatch("deleteResource", this.id);
                    await this.$router.push({name: "Home"});
                }
            },
            async create() {
                this.uploading = true;
                await this.$store.dispatch("createResource", {
                    parts: this.parts,
                    name: this.name,
                    description: this.description,
                    category: this.category,
                    subCategory: this.subCategory,
                    protected: this.protected,
                    hidden: this.hidden,
                    image: this.image
                });
                this.uploading = false;
                await this.$router.push({name: "Home"});
                await this.$bvToast.toast("Upload Successful", {
                    variant: "success"
                });
            },
            async edit() {
                this.uploading = true;
                await this.$store.dispatch("editResource", {
                    id: this.id,
                    parts: this.parts,
                    name: this.name,
                    description: this.description,
                    category: this.category,
                    subCategory: this.subCategory,
                    protected: this.protected,
                    image: this.image,
                    hidden: this.hidden
                });
                await this.$router.push({
                    name: "Home", query: {
                        current: this.category
                    }
                });
                await this.$bvToast.toast("Upload Successful", {
                    variant: "success"
                });
            },
            async back() {
                await this.$router.push({
                    name: "Home", query: {
                        current: this.category
                    }
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    label {
        width: 500px !important;
    }

    .tab-item-active {
        background-color: #FF0E49;
    }
</style>
