<template>
    <div v-if="loading" class="mt-5 text-center">
        <b-spinner type="grow" variant="dark"></b-spinner>
    </div>
    <b-container class="mt-3 mw-100 w-75" v-else>
        <div v-if="isLoggedIn">
            <b-form-group
                id="input-group-1"
                label="Category:"
                label-for="input-1"
                description="Select a category to filter by"
            >
                <b-select v-model="category" @change="loadResources(category)" placeholder="Category">
                    <template #first>
                        <b-select-option value="unprotected">
                            Public
                        </b-select-option>
                    </template>
                    <b-select-option v-for="category in categories" :value="category.key">
                        {{
                            category.name
                        }}
                    </b-select-option>
                </b-select>
            </b-form-group>
            <Transition name="fade" mode="out-in">
                <b-row v-if="!loadingResource && Object.keys(resources).length === 0">
                    <div class="text-center w-100">
                        <h2>Nothing Here Yet!</h2>
                    </div>
                </b-row>
                <div v-else-if="!loadingResource && this.category !== 'unprotected'"
                >
                    <div v-for="{key} in subCategories">
                        <template v-if="resources[key]">
                            <hr>
                            <h1>{{ subCategories.find(val => val.key === key).name }}</h1>
                            <b-card-group columns class="mt-2">
                                <b-card v-for="resource in resources[key]" :key="resource.id">
                                    <b-card-title>
                                        <b-card-img class="mb-2 mr-2 img-fluid w-15" height="100"
                                                    :src="getImageSource(resource)"></b-card-img>
                                        {{ resource.name }}
                                    </b-card-title>

                                    <b-card-sub-title v-if="isAdmin && resource.hidden">Hidden</b-card-sub-title>
                                    <b-card-sub-title v-if="isAdmin && resource.protected">Protected</b-card-sub-title>
                                    <b-card-body>{{ resource.description }}</b-card-body>
                                    <template #footer>
                                        <b-button :to="{name: 'ResourceView', params: {id: resource.id}}" class="mr-1">
                                            View
                                        </b-button>
                                        <b-button :to="{name: 'ResourceEdit', params: {id: resource.id}}" class="mr-1"
                                                  v-if="isAdmin">Edit
                                        </b-button>
                                    </template>
                                </b-card>
                            </b-card-group>
                        </template>
                    </div>
                </div>
                <b-card-group columns class="mt-2" v-else-if="!loadingResource && this.category === 'unprotected'">
                    <b-card v-for="resource in resources" :key="resource.id">
                        <b-card-title>
                            <b-card-img class="mb-2 mr-2 img-fluid w-15" height="100"
                                        :src="getImageSource(resource)"></b-card-img>
                            {{ resource.name }}
                        </b-card-title>
                        <b-card-sub-title v-if="isAdmin && resource.hidden">Hidden</b-card-sub-title>
                        <b-card-sub-title v-if="isAdmin && resource.protected">Protected</b-card-sub-title>
                        <b-card-body>{{ resource.description }}</b-card-body>
                        <template #footer>
                            <b-button :to="{name: 'ResourceView', params: {id: resource.id}}" class="mr-1">
                                View
                            </b-button>
                            <b-button :to="{name: 'ResourceEdit', params: {id: resource.id}}" class="mr-1"
                                      v-if="isAdmin">Edit
                            </b-button>
                        </template>
                    </b-card>
                </b-card-group>
                <b-row v-else>
                    <div class="text-center mt-5 w-100">
                        <b-spinner type="grow"></b-spinner>
                    </div>
                </b-row>
            </Transition>
        </div>
        <div v-else>
            <b-card-group columns class="mt-2">
                <b-card v-for="resource in resources" :key="resource.id">
                    <b-card-title>
                        <b-card-img class="mb-2 mr-2 img-fluid w-15" height="100"
                                    :src="getImageSource(resource)"></b-card-img>
                        {{ resource.name }}
                    </b-card-title>
                    <b-card-sub-title v-if="isAdmin && resource.hidden">Hidden</b-card-sub-title>
                    <b-card-sub-title v-if="isAdmin && resource.protected">Protected</b-card-sub-title>
                    <b-card-body>{{ resource.description }}</b-card-body>
                    <template #footer>
                        <b-button :to="{name: 'ResourceView', params: {id: resource.id}}" class="mr-1">
                            View
                        </b-button>
                        <b-button :to="{name: 'ResourceEdit', params: {id: resource.id}}" class="mr-1"
                                  v-if="isAdmin">Edit
                        </b-button>
                    </template>
                </b-card>
            </b-card-group>
            <h3 class="mt-5 mb-5 text-center">
                <router-link class="text-secondary" to="/login">Sign In</router-link>
                to view more Resources
            </h3>
        </div>
    </b-container>
</template>

<script>
    import {mapGetters} from "vuex";
    import {getCategories, getSubCategories} from "@/services/meta";

    export default {
        name: "Home",
        props: {
            current: null,
            apply: null
        },
        data() {
            return {
                loading: true,
                categories: [],
                subCategories: [],
                category: null,
                subCategory: null,
                loadingResource: true
            };
        },
        computed: {
            ...mapGetters(['resources', 'isLoggedIn', 'isAdmin'])
        },
        watch: {
            async $route(to, from, next) {
                if (to !== from) {
                    await this.onCreate();
                }
            }
        },
        async created() {
            await this.onCreate();
        },
        methods: {
            async onCreate() {

                if (this.isLoggedIn) {
                    this.categories = await getCategories();
                    this.subCategories = await getSubCategories();
                    this.category = "unprotected";
                    if (this.current) {
                        this.category = this.categories.find(val => val.key === this.current).key;
                    }
                    await this.loadResources(this.category);
                } else {
                    await this.$store.dispatch('getNonProtectedResources');
                }
                this.loading = false;
            },
            async loadResources(cat) {
                this.category = cat;
                this.loadingResource = true;
                if (this.category === "unprotected") {
                    await this.$store.dispatch('getNonProtectedResources');
                } else {
                    await this.$store.dispatch('getResources', {
                        category: cat,
                    });
                }
                const searchParams = new URLSearchParams(window.location.search);
                searchParams.set("current", this.category);
                const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
                history.pushState(null, '', newRelativePathQuery);
                this.loadingResource = false;
            },
            isCategoryActive(category) {
                return this.category.key === category.key;
            },
            isSubCategoryActive(category) {
                return this.subCategory.key === category.key;
            },
            getImageSource(resource) {
                if (resource.image) {
                    return resource.image;
                } else return "SAG-logo-alpha-small-logo.png";
            }

        }
    };
</script>

<style scoped lang="scss">

</style>
