<template>
    <UserAdd @apply="addUser" title="Add a User" submit="Add"/>
</template>

<script>
    import UserAdd from "@/components/UserAdd";
    import router from "@/router";

    export default {
        name: "NewUser",
        components: {UserAdd},
        methods: {
            async addUser(form) {
                const resp = await this.$store.dispatch('createUser', form);
                if (resp) {
                    await this.$bvToast.toast(resp, {
                        title: 'User Create Error',
                        variant: 'danger'
                    });
                } else {
                    await this.$bvToast.toast("User Created", {
                        title: 'User',
                        variant: 'success',
                        autoHideDelay: 5000
                    });
                    await router.push({name: "Users"});
                }
            }
        }
    };
</script>

<style scoped>

</style>
