<template>
    <div>
        <b-navbar toggleable="lg" type="dark" variant="dark">
            <b-navbar-brand :to="{name: 'Home'}"><img src="/SAG-logo-alpha-small-logo.png" height="60px"/><img
                src="/SAG-logo-alpha-small-text.png" height="30px"/></b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>


                <b-navbar-nav class="ml-auto">
                    <b-nav-item-dropdown right v-if="isLoggedIn">
                        <!-- Using 'button-content' slot -->
                        <template #button-content>
                            <em>Actions</em>
                        </template>
                        <b-dropdown-item v-if="isAdmin" :to="{name: 'Users'}">Users</b-dropdown-item>
                        <b-dropdown-item v-if="isAdmin" :to="{name: 'ResourceAdd', params: {id: -1}}">Add Resource
                        </b-dropdown-item>
                        <b-dropdown-item :to="{name: 'Logout'}">Sign Out</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item :to="{name: 'Login'}" v-else>
                        Sign In
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "NavBar",
        computed: {
            ...mapGetters(['isAdmin', 'isLoggedIn'])
        }
    };
</script>

<style scoped>

</style>
